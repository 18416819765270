import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "photoswipe/dist/photoswipe.css"
//import "photoswipe/dist/default-skin/default-skin.css"

import { Gallery, Item } from "react-photoswipe-gallery"

import parse from "html-react-parser"

const IndexPage = () => {

  const pageQuery = useStaticQuery(graphql`
      query Gazdasag {
          markdownRemark(frontmatter: {pagename: {eq: "index"}}) {
              frontmatter {
                  title
                  gallery {
                      text
                      image {
                          childImageSharp {
                              thumbnail: fluid(maxWidth: 300, fit: CONTAIN) {
                                  src
                              }
                              fluid(maxWidth: 900, fit: CONTAIN) {
                                  src
                              }
                              original {
                                  src
                                  width
                                  height
                              }
                          }

                      }
                  }
              }
              html
          }
      }


  `)

  const { html, frontmatter } = pageQuery?.markdownRemark
  const { title, gallery } = frontmatter

  const pageHtml = html.split("---GALLERY---")

  const smallItemStyles = {
    cursor: "pointer",
    objectFit: "cover",
    width: "100%",
    maxHeight: "100%"
  }

  return <>
    <Layout>
      <Seo title={title} />
      {parse(pageHtml.length > 1 ? pageHtml[0] : html || "")}

      {gallery.length && <>
        <Gallery zoomButton={false} fullscreenButton={false} shareButton={false}>
          <div className="gallery-container">
            {gallery.map((galleryItem, index) => {
                return <Item
                  key={`gallery-${index}`}
                  original={galleryItem.image.childImageSharp.fluid.src}
                  thumbnail={galleryItem.image.childImageSharp.fluid.src}
                  width={galleryItem.image.childImageSharp.original.width}
                  height={galleryItem.image.childImageSharp.original.height}
                >
                  {({ ref, open }) => (
                    // JSON.stringify(galleryItem?.image?.childImageSharp.fluid)
                    // <Img fluid={galleryItem?.image?.childImageSharp.fluid} ref={ref} onClick={open} />
                    <img style={smallItemStyles} src={galleryItem.image.childImageSharp.thumbnail.src} ref={ref}
                         onClick={open} />
                  )}</Item>
              }
            )
            }
          </div>
        </Gallery>
      </>}


      { pageHtml.length > 1 &&  parse(pageHtml[1] || '')}


    </Layout>
  </>
}

export default IndexPage
